<template>
    <div class="home">
    <p class="mb-4">
    <section class="hero is-primary">
  <div class="hero-body">
    <p class="title">
      Small Invoice - MS Teams Dashboard
    </p>
    <p class="subtitle">
      Link-Generator
    </p>
  </div>
</section>
</p>
  <div v-if="users.length ==[]" class="container" style="width: 400px; margin-top: 150px;">
 <article class="message message is-info">
  <div class="message-header">
    <p>Daten werden abgerufen</p>
    <button class="delete" aria-label="delete"></button>
  </div>
  <div class="message-body">
    Die Adressdatenbank aufzubauen kann bis zu 30 Sekunden dauern. Bitte etwas Geduld...
    
  </div>
  <progress class="progress is-small is-info" max="100">15%</progress>
</article>
</div>
<div v-if="users.length !=[]" class="container">
      <input class="input is-primary" type="text" placeholder="Suche..." v-model="filters.name.value"/>
  <div style="height: 450px;">
      <v-table class="table table is-striped table is-narrow table is-fullwidth" 

        :data="users"
        :filters="filters"
        :currentPage.sync="currentPage"
        :pageSize="10"
        
        @totalPagesChanged="totalPages = $event"
      >
        <thead slot="head">
        <th>ID</th>
        <th>Nummer</th>
        <th>Name</th>
        <th>Zusatz</th>
        
        

        </thead>



        <tbody slot="body" slot-scope="{displayData}">
        <tr v-for="row in displayData" :key="row.guid" @click="selectRow(row.id) " :class="{'is-selected has-background-grey-lighter has-text-black': (row.id == RowSelect)}">
          <td>{{ row.id }}</td>
          <td>{{ row.number }}</td>
          <td>{{ row.name }}</td>
          <td>{{ row.name_addition }}</td>
          
          

        </tr>
        </tbody>
        
      </v-table>
      </div>

      <div class="">
  <div class="navbar">
            <smart-pagination
        :currentPage.sync="currentPage"
        :totalPages="totalPages"
        :directionLinks="directionLinks"
        :maxPageLinks="maxPageLinks"
      />
      </div>
  </div>  

</div>
<div v-if="users.length !=[]" class="container">
<input class="input is-primary" type="text" placeholder="https://2ucs.ch/" v-model="url"/>
</div>

  </div>
</template>

<script>
import SI_API from "../api/Small_Invoice_API.js";
export default {
      components: {

    },
  data() {
    return {
      contacts: [],
    tabselect: "P", 
    RGSelect: "", 
    RowSelect:"",
    localpages:"",
    users: [],
    url:"https://",
    filters: {
      name: { value: '', keys: ['name'] },
      
    },
    currentPage: 1,
    totalPages: 0,
    directionLinks: true,
    maxPageLinks: 15
    };
  },
  name: "link",

  async created() {
 
    SI_API.GetNewToken(this.$route.query.clientid).then((response) => {
        SI_API.GetContactsAll(response).then(
        ((response) => {
          this.users=response
        }).bind(this)
        
      );
    });

  },
     methods: {
    sortBy: function(sortKey) {
      this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
      this.sortKey = sortKey;
    },
    selectRow(payload){
    this.url="https://smallinvoiceteams.2ucs.ch/?id="+payload+"&clientid="+this.$route.query.clientid
    this.RowSelect=payload;
    },
   
     }
};
</script>

<style>

</style>